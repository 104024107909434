<template>
	<w-layout row justify-center align-center>
		<w-flex v-text="application.last_version"></w-flex>
	</w-layout>
</template>

<script>
export default {
	name: 'MobileAppVersion',
	props: {
		value: {
			required: true,
			type: Object
		}
	},
	computed: {
		application: {
			get: function () {
				return this.value
			},
			set: function (val) {
				this.$emit('input', val)
			}
		}
	}
}
</script>
